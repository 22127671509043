import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux';
import {unregister} from './serviceWorker';
import {i18n} from '@lingui/core';
import {I18nProvider} from '@lingui/react';
import {messages} from './locales/de/messages';
import {de} from 'make-plural/plurals';

i18n.load('de', messages);
i18n.loadLocaleData({
    de: {plurals: de},
});
i18n.activate('de');
ReactDOM.render(
    <React.StrictMode>
        <I18nProvider i18n={i18n}>
            <Provider store={store}>
                <App />
            </Provider>
        </I18nProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
