import {useAppSelector} from 'app/hooks';
import {selectLogin} from 'features/login/loginSlice';
import React, {useState} from 'react';
import {t} from '@lingui/macro';
import {
    selectRecoveryFailed,
    selectRecoverySucceeded,
} from './lostPasswordSlice';
import {parse} from 'query-string';
import SkeletonComponent from 'features/page/SkeletonComponent';
import {LinkDTO, PREDEFINED} from 'features/page/LinkList';
import LostPasswordForm from './sub/LostPasswordForm';

enum RECOVERY_STATE {
  INPUT_MAIL,
  CLICK_BTN,
}

const LostPassword = () => {
    const recoverySucceeded = useAppSelector(selectRecoverySucceeded);
    const recoveryFailed = useAppSelector(selectRecoveryFailed);
    const login = useAppSelector(selectLogin);
    const [isSent, setIsSent] = useState<boolean>(false);
    /* eslint-disable-next-line no-restricted-globals */
    const {flow, token} = parse(location.search);
    const step = flow && token ? RECOVERY_STATE.CLICK_BTN : RECOVERY_STATE.INPUT_MAIL;
    /* rendering */
    if (recoveryFailed) {
        return (
            <form className="lost-password">
                <span>{t`recovery.failed.error.text`}</span>
            </form>
        );
    }

    const links:LinkDTO[] = [
        PREDEFINED.LOGIN_ALTERNATE_TEXT,
        PREDEFINED.SUPPORT,
    ];
    const getExplanation = (step: RECOVERY_STATE) => {
        switch (step) {
        case RECOVERY_STATE.CLICK_BTN:
            return 'lostPassword.explanation.click_btn';
        case RECOVERY_STATE.INPUT_MAIL:
            return 'lostPassword.explanation.input_mail';
        }
    };
    const showError = false;
    const redirect = () => {
        const p = window.location.protocol;
        const idService = window.location.host.split(/\./).slice(1).reverse().concat('id').reverse().join('.');
        window.location.href = `${p}//${idService}/self-service/recovery${window.location.search}`;
    };
    return (
        <SkeletonComponent
            title={'lostPassword.title'}
            explanation={
                recoverySucceeded
                    ? ('lostPassword.explanation.success')
                    : getExplanation(step)
            }
            links={links}
            translationTokens={{mail: login}}
        >
            { step === RECOVERY_STATE.INPUT_MAIL && !isSent && <LostPasswordForm onSend={() => setIsSent(true)}/>}
            {
                step === RECOVERY_STATE.CLICK_BTN
                && <div className={'form-component'} style={{width: '100%'}}>
                    <div
                        tabIndex={0}
                        className={
                            `cl-button cl-button-animated `
                        }
                        style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '50%', width: '100%'}}
                        onClick={redirect}
                        onKeyPress={(e) => {
                            if ([' ', 'Enter'].includes(e.key)) {
                                redirect();
                            }
                        }}
                    >
                        <div>{t`recovery.from_email.continue.button.text`}</div>
                    </div>
                </div>
            }
            { showError && <span className="error-message">Email-Adresse nicht korrekt</span>}
        </SkeletonComponent>
    );
};

export default LostPassword;
