import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import axios from "axios";

export interface RegisterFormState {
  isRegistering: boolean;
  errors: string[];
  success: boolean;
  /* form now */
  form: {
    ustId: string;
    companyName: string;
    firstName: string;
    lastName: string;
    street: string;
    streetNumber: string;
    postal: string;
    city: string;
    phone: string;
    mobile: string;
    fax: string;
    email: string;
    website: string;
    countryCode: string;
    newsletter: string;
    password: string;
    passwordRepeat: string;
  };
}
const initialState: RegisterFormState = {
  errors: [],
  success: false,
  isRegistering: false,
  /* form */
  form: {
    ustId: "",
    companyName: "",
    firstName: "",
    lastName: "",
    street: "",
    streetNumber: "",
    postal: "",
    city: "",
    phone: "",
    mobile: "",
    fax: "",
    email: "",
    website: "",
    countryCode: "de",
    newsletter: "1",
    password: "",
    passwordRepeat: "",
  },
};
const setToState =
  (field: keyof RegisterFormState["form"]) =>
  (state: Draft<RegisterFormState>, action: PayloadAction<string>) => {
    state.form[field] = action.payload;
  };
export const registerFormSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setUstId: setToState("ustId"),
    setCompanyName: setToState("companyName"),
    setFirstName: setToState("firstName"),
    setLastName: setToState("lastName"),
    setStreet: setToState("street"),
    setStreetNumber: setToState("streetNumber"),
    setEmail: setToState("email"),
    setPostal: setToState("postal"),
    setCity: setToState("city"),
    setMobile: setToState("mobile"),
    setPhone: setToState("phone"),
    setFax: setToState("fax"),
    setWebsite: setToState("website"),
    setCountryCode: setToState("countryCode"),
    setPassword: setToState("password"),
    setPasswordRepeat: setToState("passwordRepeat"),
    setNewsletter: (state, action: PayloadAction<boolean>) => {
      state.form.newsletter = action.payload ? "1" : "0";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAsync.pending, (state) => {
        state.isRegistering = true;
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        state.isRegistering = false;
        if (action.payload) {
          state.errors = action.payload.errors;
          state.success = action.payload.success;
        }
      });
  },
});

export type RegisterFormDataDTO = {
  companyName: string;
  contact: {
    firstName: string;
    lastName: string;
  };
  address: {
    street: string;
    streetNumber: string;
    postal: string;
    city: string;
    phone: string;
    mobile: string;
    fax: string;
    email: string;
    website: string;
    countryCode: string;
    newsletter: boolean;
    ustIdNumber: string;
  };
  password: string;
};
export type RegisterResponseDTO = {
  success: boolean;
  errorCodes?: number[];
  errors: string[];
};
export const registerAsync = createAsyncThunk<
  RegisterResponseDTO,
  RegisterFormDataDTO
>("register/register", async (registerFormData: RegisterFormDataDTO) => {
  const response = await axios.post<RegisterResponseDTO>(
    "/janus/register",
    registerFormData
  );
  return response.data;
});

export const selectErrors = (state: RootState) => state.registerForm.errors;
export const selectSuccess = (state: RootState) => state.registerForm.success;
export const selectIsRegistering = (state: RootState) =>
  state.registerForm.isRegistering;
/* form */
export const selectPassword = (state: RootState) =>
  state.registerForm.form.password;
export const selectPasswordRepeat = (state: RootState) =>
  state.registerForm.form.passwordRepeat;
export const selectUstId = (state: RootState) => state.registerForm.form.ustId;
export const selectFirstName = (state: RootState) =>
  state.registerForm.form.firstName;
export const selectLastName = (state: RootState) =>
  state.registerForm.form.lastName;
export const selectCompanyName = (state: RootState) =>
  state.registerForm.form.companyName;

export const selectStreet = (state: RootState) =>
  state.registerForm.form.street;
export const selectStreetNumber = (state: RootState) =>
  state.registerForm.form.streetNumber;
export const selectEmail = (state: RootState) => state.registerForm.form.email;
export const selectPostal = (state: RootState) =>
  state.registerForm.form.postal;
export const selectFax = (state: RootState) => state.registerForm.form.fax;
export const selectCity = (state: RootState) => state.registerForm.form.city;
export const selectPhone = (state: RootState) => state.registerForm.form.phone;
export const selectMobile = (state: RootState) =>
  state.registerForm.form.mobile;
export const selectWebsite = (state: RootState) =>
  state.registerForm.form.website;
export const selectCountryCode = (state: RootState) =>
  state.registerForm.form.countryCode;
export const selectNewsletter = (state: RootState) =>
  state.registerForm.form.newsletter === "1";
export const {
  setUstId,
  setCompanyName,
  setFirstName,
  setLastName,
  setStreet,
  setStreetNumber,
  setEmail,
  setPassword,
  setPasswordRepeat,
  setPostal,
  setCity,
  setMobile,
  setPhone,
  setFax,
  setWebsite,
  setCountryCode,
  setNewsletter,
} = registerFormSlice.actions;
export default registerFormSlice.reducer;
