import React from "react";
import SkeletonComponent from "features/page/SkeletonComponent";

const Support = () => {
  return (
    <SkeletonComponent prefix="support" links={[]}>
      <p>
        Wenden Sie sich gerne telefonisch oder per email an unseren Support.
        <br />
        Telefon Österreich: <a href="tel:+43724129429">
          +43 (0) 7241 29429
        </a>{" "}
        <br />
        Telefon Deutschland: <a href="tel:+4998439804300">
          +49 (0) 9843 980430 0
        </a>{" "}
        <br />
        E-Mail:{" "}
        <a
          href="mailto:office@speedmaster.at"
          target="_blank"
          rel="noopener noreferrer"
        >
          office@speedmaster.at
        </a>
      </p>
      <div
        style={{
          position: "relative",
          width: "234px",
          height: "60px",
          marginTop: "40px",
        }}
      >
        <a
          style={{ textDecoration: "none" }}
          href="https://www.speedmaster.at/wp-content/uploads/TeamViewerSpeedMaster.exe"
        >
          <img
            title="TeamViewer – die Software für den Zugriff auf PCs über das Internet"
            src="http://www.teamviewer.com/link/?url=985962&amp;id=1200819466"
            alt="TeamViewer – die Software für den Zugriff auf PCs über das Internet"
            className="load-finished"
            width="234"
            height="60"
          />
          <span
            style={{
              position: "absolute",
              top: "30.5px",
              left: "60px",
              display: "block",
              cursor: "pointer",
              color: "#424242",
              fontFamily: "Arial",
              fontSize: "12px",
              lineHeight: "1.2em",
              fontWeight: "bold",
              textAlign: "center",
              width: "169px",
            }}
          >
            Fernwartung Speedmaster
          </span>
        </a>
      </div>
    </SkeletonComponent>
  );
};
export default Support;
